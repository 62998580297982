<template>
  <div
    :class="[
      { 'container-article': article },
      { 'container-consumer': consumer }
    ]"
  >
    <div :class="['container', { 'card' : article }]">
      <header v-if="!hideElement('title')">
        <h3
          v-if="placement === 'sidebar' || article"
          :class="{ 'title': consumer }"
        >
          {{ contextContent('title', true) }}
        </h3>
        <h1
          v-else
          :class="{ 'title': consumer }"
        >
          {{ contextContent('title', true) }}
        </h1>

        <h2 v-if="placement !== 'sidebar' && contextContent('subtitle', false) && !contextContent('info', true)">
          {{ contextContent('subtitle', false) }}
        </h2>
        <p
          v-if="isZmarta && contextContent('co-company', false)"
          class="co-company"
        >
          {{ contextContent('co-company', false) }}<zg-logo brand="elskling" />
        </p>
      </header>

      <zg-tips
        v-if="contextContent('product-options', false) && consumer"
        class="product"
        :items="JSON.parse(contextContent('product-options', false)).map(p => ({ ...p, selected: p.key === product }))"
        @click="product = $event"
      />

      <zg-checkbox
        v-if="contextContent('microgeneration-label') && consumer && product === 'consumer'"
        v-model="microgeneration"
        name="microgeneration"
      >
        {{ contextContent('microgeneration-label') }}
      </zg-checkbox>

      <zg-input
        v-model.trim="zipCode.value"
        name="zipCode"
        type="tel"
        :attrs="[{ 'maxlength': getProp(INPUT_LENGTH, 'ZIP_CODE', 'REQUIRED') }]"
        :label="contextContent('zip-code-label', false)"
        :full-width="!consumer || (consumer && getScreenWidth === 'mobile')"
        :valid="zipCode.valid"
        :invalid="zipCode.invalid"
        :error="errorContent"
        @blur="CheckIfEmptyOrIncompleteZipCode"
        @focusin.native="sendFormStartEvent($event)"
        @input.native.once="sendFormStartEvent($event)"
        @keyup.enter.native="clickSubmit()"
      />

      <zg-transition-swipe direction="right">
        <div
          v-show="postalCity"
          class="postal-city"
        >
          <zg-icon
            icon="pin"
            size="small"
          />
          {{ postalCity }}
        </div>
      </zg-transition-swipe>

      <zg-button
        ref="submit"
        :class="{ 'input-width': consumer }"
        :style="{'background-color': getProp(getQuery, 'buttonColor')
          ? `#${getProp(getQuery, 'buttonColor')}`
          : false }"
        type="button"
        :icons="{ right: 'arrow-forward' }"
        :loading="loading"
        :full-width="(getScreenWidth !== 'desktop' && !consumer) ||
          (consumer && getScreenWidth === 'mobile')"
        size="xlarge"
        @click="sendGaEventsAndProceedToForm()"
      >
        {{ getProp(getQuery, 'ctaActionButtonText') || (getProp(content, `zip-code-form.submit-${product}`) ?? contextContent('submit', true)) }}
      </zg-button>

      <zg-usps
        v-if="!hideElement('usps')"
        :usps="uspsContent"
      />
    </div>
  </div>
</template>

<script>
  import { ZgTips } from '@zc/components/ZgTips'
  import { ZgCheckbox } from '@zc/components/ZgCheckbox'
  import { ZgInput } from '@zc/components/ZgInput'
  import { ZgTransitionSwipe } from '@zc/components/ZgTransitionSwipe'
  import { ZgIcon } from '@zc/components/ZgIcon'
  import { ZgButton } from '@zc/components/ZgButton'
  import { ZgLogo } from '@zc/components/ZgLogo'
  import { ZgUsps } from '@zc/components/ZgUsps'
  import { capitalizeFirstLetter, domPurify } from '@utils'
  import { getProp, hasProp, isEmpty, size } from '@ocp-zmarta/zmarta-cl'
  import requests from '../../requests'
  import { mapGetters } from 'vuex'

  export default {
    name: 'ZipCodeForm',
    components: {
      ZgTips,
      ZgCheckbox,
      ZgInput,
      ZgTransitionSwipe,
      ZgIcon,
      ZgButton,
      ZgLogo,
      ZgUsps
    },
    props: {
      content: {
        type: Object,
        required: true
      },
      localContext: {
        type: String,
        required: false,
        default: 'default'
      }
    },
    data: () => ({
      loading: false,
      product: 'consumer',
      microgeneration: false,
      zipCode: {
        value: '',
        valid: false,
        invalid: false
      },
      postalCity: null,
      sentFormStartEvent: {
        consumer: false,
        business: false
      }
    }),
    computed: {
      ...mapGetters({
        getQuery: 'router/getQuery',
        getDomain: 'router/getDomain',
        getScreenWidth: 'utils/getScreenWidth',
        getCountry: 'utils/getCountry'
      }),
      isZmarta () {
        return this.BRAND === 'zmarta'
      },
      context () {
        return getProp(this.getQuery, 'context') || this.localContext
      },
      article () {
        return this.context === 'article'
      },
      consumer () {
        return this.context === 'consumer'
      },
      placement () {
        return getProp(this.getQuery, 'placement')
      },
      errorContent () {
        if (isEmpty(this.zipCode.value)) {
          return this.contextContent('zip-code-empty', false)
        } else if (size(this.zipCode.value) !== getProp(this.INPUT_LENGTH, 'ZIP_CODE', 'REQUIRED') || isNaN(this.zipCode.value)) {
          return this.contextContent('zip-code-incomplete', false)
        } else {
          return this.contextContent('zip-code-error', false)
        }
      },
      uspsContent () {
        const usps = []
        if (this.content) {
          for (const [key, value] of Object.entries(this.content)) {
            if (key.startsWith('zip-code-form.usp')) {
              if (value) usps.push({ title: value })
            }
          }
        }
        return usps
      },
      getPageType () {
        const path = window.location.pathname
        const regExArticlePage = /\/(el|strom|sahko)\/[^/]+$/
        const regExPartnerPage = /\/(el\/elbolag|strom\/stromleverandorer|sahko\/sahkoyhtiot)\/[^/]+$/

        if (path === '/') return 'startPage'
        else if (['/el', '/strom', '/sahko'].includes(path)) return 'electricityPage'
        else if (regExArticlePage.test(path)) return 'articlePage'
        else if (regExPartnerPage.test(path)) return 'partnerPage'
        else return ''
      },
      formPageUrl () {
        const params = new URLSearchParams(window.location.search)
        params.set('zipcode', this.zipCode.value)
        params.set('postalCity', this.postalCity)
        params.set('compareMicrogeneration', this.microgeneration)

        const baseUrl = this.FORM_PAGE[this.getDomain][this.product]
        return `${baseUrl}?${params.toString()}`
      }
    },
    watch: {
      'zipCode.value' (v) {
        this.getPostalCity(v)
      }
    },
    mounted () {
      if (this.context === 'business') this.product = this.context
      if (this.context === 'microgeneration') this.microgeneration = true
    },
    methods: {
      getProp,
      size,
      domPurify,
      hideElement (element) {
        return (hasProp(this.getQuery, 'hideElements') &&
          getProp(this.getQuery, 'hideElements')
            .toLowerCase()
            .split(',')
            .find(e => e.trim() === element)) ||
          // legacy to not break old versions
          getProp(this.getQuery, `hide${capitalizeFirstLetter(element)}`) === 'true'
      },
      contextContent (type, contextSpecific) {
        if (contextSpecific) {
          return getProp(this.content, `zip-code-form.${type}-${this.context}`) ?? getProp(this.content, `zip-code-form.${type}-default`)
        } else {
          return getProp(this.content, `zip-code-form.${type}`)
        }
      },
      async getPostalCity (zipCode = this.zipCode.value) {
        if (size(zipCode) === getProp(this.INPUT_LENGTH, 'ZIP_CODE', 'REQUIRED') && !isNaN(zipCode)) {
          const response = await requests.get(`${this.SERVICES.elskling.replace('{country}', this.getCountry)}/${this.getCountry}/helper/postalcity/${this.zipCode.value}`)

          if (getProp(response, 'status', 'status') === 'OK') {
            this.postalCity = getProp(response, 'postalCity')
            this.zipCode.valid = true
            this.zipCode.invalid = false
          } else {
            this.zipCode.valid = false
            this.zipCode.invalid = true
          }
        } else {
          this.postalCity = null
          this.zipCode.valid = false
          this.zipCode.invalid = false
        }
      },
      CheckIfEmptyOrIncompleteZipCode () {
        if (size(this.zipCode.value) !== getProp(this.INPUT_LENGTH, 'ZIP_CODE', 'REQUIRED') || isNaN(this.zipCode.value)) {
          this.postalCity = null
          this.zipCode.valid = false
          this.zipCode.invalid = true
        }
      },
      sendFormClickEvent (label) {
        this.ZGA.event.form.click({
          step: this.GA.FORM_STEP,
          label
        })
      },
      clickSubmit () {
        this.$refs.submit.$el.click()
      },
      async sendGaEventsAndProceedToForm () {
        if (!this.postalCity && !this.zipCode.invalid) await this.getPostalCity()
        if (!this.zipCode.valid) {
          this.zipCode.invalid = true
          return
        }

        try {
          this.sendFormClickEvent(this.GA.BUTTON_LABEL_COMPARE)
          this.ZGA.event.form.click({
            step: this.GA.FORM_STEP,
            label: this.GA.BUTTON_LABEL_COMPARE
          })
          this.sendFormStartEvent()
        } catch (e) {
          console.error(e)
        }

        if (this.zipCode.valid) {
          this.EXPERIMENTS.track('prod-cta-variable')
          const redirect = getProp(this.getQuery, 'r')
          if (redirect) {
            if (getProp(this.getQuery, 'openInNewTab')) {
              setTimeout(() => {
                window.open(this.redirectUrlFormatted(redirect), '_blank')
              }, 0)
            } else {
              window.top.location.replace(this.redirectUrlFormatted(redirect))
            }
          } else {
            window.top.location.href = this.formPageUrl
          }
        }
      },
      sendFormStartEvent (event) {
        if (this.sentFormStartEvent[this.product]) return

        this.ZGA.event.form.firstInteraction({
          step: this.GA.FORM_STEP,
          fieldName: getProp(event, 'target', 'name') ?? getProp(this.getQuery, 'ctaActionButtonText'),
          fieldType: getProp(event, 'target', 'type') ?? 'button'
        })
        this.EXPERIMENTS.track('electricity-application-formstart')
        this.sentFormStartEvent[this.product] = true
      },
      redirectUrlFormatted (redirect) {
        const query = `zipcode=${this.zipCode.value}&postalCity=${this.postalCity}`
        if (redirect?.match(/(zmarta|elskling).*\?/)) {
          return `${redirect}&${query}`
        } else {
          return `${redirect}?${query}`
        }
      },
      replaceDomain (str) {
        return str.replace('{{domain}}', capitalizeFirstLetter(this.getDomain))
      }
    }
  }
</script>

<style lang="scss" scoped>
h1 {
  @include type-title-l;

  &:has(+ h2) {
    margin-bottom: 0;
  }
}

h2 {
  @include type-title-s;

  font-weight: normal;
}

button {
  margin-bottom: rhythm("base");
}

.product {
  margin-bottom: rhythm("small");
}

.postal-city {
  display: flex;
  margin-top: - rhythm("half");
  margin-bottom: rhythm("half");
}

.container {
  max-width: rem(834px);

  @include mq("medium") {
    .input-width {
      width: input(width);
    }
  }
}

.card {
  max-width: rem(592px);
  border: 1px solid rgb(0 0 0 / 10%);
  padding: rhythm("small");
  box-shadow: 0 1px 1px rgb(0 0 0 / 12%);
  margin-bottom: rhythm("small");
}

.container-article {
  max-width: rem(1090px);
  margin: rhythm("small");

  @include mq("large") {
    margin-left: auto;
    margin-right: auto;
  }

  .card {
    background-color: color(white);
  }
}

.container-consumer {
  h1 {
    @include type-title-l;

    @include mq("medium") {
      @include type-title-xl;

      margin-bottom: rhythm("xsmall");
    }
  }

  button {
    margin-bottom: rhythm("small");
  }

  .postal-city {
    margin-top: - rhythm("xsmall");
    margin-bottom: rhythm("half");
  }

  ::v-deep .zg-form-element {
    margin-bottom: rhythm("small");

    .zg-checkbox label {
      padding: 0;
    }
  }
}

.info-text ::v-deep a {
  color: color(primary-500);
  font-weight: bold;
  text-decoration: underline;
  border-bottom: none;
}

.link {
  color: color(primary-500);
  text-decoration: underline;
  font-weight: bold;
  cursor: pointer;
}

.products-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: rhythm("small");
  margin-bottom: rhythm("small");

  @include mq("medium") {
    grid-auto-flow: column;
    grid-auto-columns: max-content;
    grid-template-columns: unset;
  }
}

.info-modal-list {
  list-style-type: "-";

  ::v-deep li {
    padding-left: rhythm("small");
  }
}

.zg-modal[name="shopModal"] ::v-deep {
  h2 {
    margin-bottom: rhythm("large");
  }

  a {
    border-bottom: none;
  }
}

.co-company {
  display: flex;
  gap: rhythm(0.5);
  align-items: center;
  margin-bottom: 1rem;

  ::v-deep svg {
    height: 1.3rem;
    width: auto;
  }
}
</style>
